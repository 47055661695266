import React from "react";
import styled from "styled-components";
import { graphql, PageProps } from "gatsby";

import ContactForm from "@components/contact/contactForm";
import ContactInfo from "@components/contact/contactInfo";
import { Container } from "@util/standard";
import { Query } from "@graphql-types";
import SEO from "@components/shared/seo";
import { MOBILE_BREAKPOINT } from "@util/constants";

const Wrapper = styled(Container)`
  flex-direction: column;
  width: 100%;
  margin: 250px 0 50px 0;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 100px 0 50px 0;
  }
`;

interface Props extends PageProps {
  data: Query;
}

export default function Contact({ data }: Props) {
  const { sanityContact } = data;
  return (
    <Wrapper>
      <SEO seoData={sanityContact?.seo} slug="contact" />
      <ContactInfo data={sanityContact?.headerTextContent} />
      <ContactForm />
    </Wrapper>
  );
}

export const query = graphql`
  query ContactQuery {
    sanityContact {
      seo {
        pageDescription
        pageKeyWords
        pageTitle
        jsonLD {
          _key
          _type
          language
          filename
          code
        }
        slug {
          current
        }
        ogImage {
          asset {
            url
          }
        }
      }
      headerTextContent {
        _rawColumnContent
      }
    }
  }
`;
